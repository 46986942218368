<template>
    <div class="content">
        <div class="side">
            <span class="side__title">Тип причины увольнения</span>
            <el-select
                v-model="dismissalType"
                filterable
                placeholder="не выбрано"
                class="side__select"
            >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                />
            </el-select>
            <span class="side__title">Период</span>
            <select-date
                v-model="filter.datePicked"
                class="side__date-select"
            />
            <span class="side__title">Подразделение</span>
            <el-select
                v-model="filter.subdivision"
                clearable
                filterable
                placeholder="не выбрано"
                class="side__select"
            >
                <el-option
                    v-for="item in subdivisions"
                    :key="item.ID"
                    :value="item.ID"
                    :label="item.VALUE"
                />
            </el-select>
        </div>
        <div class="chart">
            <apex-chart
                v-if="boot"
                :data="charts"
                :labels="legendLabels"
            />
        </div>
    </div>
</template>

<script>

import SelectDate from '@/components/SelectDate';
import ApexChart from '@/components/ApexChart';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/ru';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(isLeapYear);
dayjs.locale('ru');

export default {
    name: 'App',
    components: {
        ApexChart,
        SelectDate
    },
    data () {
        return {
            boot: false,
            options: [{
                value: 'main',
                label: 'Основные причины'
            }, {
                value: 'secondary',
                label: 'Второстепенные причины'
            }, {
                value: 'all',
                label: 'Все причины увольнения'
            }],
            filter: {
                subdivision: '',
                datePicked: [
                    dayjs().startOf('year').format('YYYY-MM-DD'),
                    dayjs().format('YYYY-MM-DD')
                ]
            },
            dismissalType: 'all',
            dismissalReasons: [],
            subdivisions: [],
            application: []
        };
    },

    computed: {
        data () {
            const data = [];

            this.dismissalReasons.forEach(reason => {
                const mainItems = this.application.filter(i => String(i.ufCrm_1_1650622987) === String(reason.ID));
                const secondItems = this.application.filter(i => (i.ufCrm_1_1650623047 ?? []).includes(reason.ID));

                let value = 0;
                if (this.dismissalType === 'all') {
                    value = mainItems.length + secondItems.length * 0.25;
                } else if (this.dismissalType === 'secondary') {
                    value = secondItems.length * 0.25;
                } else if (this.dismissalType === 'main') {
                    value = mainItems.length;
                }

                data.push({
                    name: reason.NAME,
                    value
                });
            });

            return data;
        },

        legendLabels () {
            return this.data
                .filter(item => item.value > 0)
                .map(item => item.name);
        },

        charts () {
            return this.data
                .filter(item => item.value > 0)
                .map(item => item.value);
        }
    },

    watch: {
        filter: {
            handler (value) {
                if (value) {
                    this.fetchApplications();
                }
            },

            deep: true
        }
    },

    created () {
        BX24.init(async () => {
            await Promise.all([
                this.fetchDissmisalType(),
                this.fetchApplications(),
                this.fetchSubdivisions()
            ]);
            this.boot = true;
        });
    },

    methods: {
        callMethod (method, params) {
            return new Promise((resolve, reject) => {
                BX24.callMethod(method, params, result => {
                    if (result.status !== 200 || result.error()) {
                        return reject(result);
                    }

                    return resolve(result.data());
                });
            });
        },

        callListMethod (method, params, key) {
            return new Promise((resolve, reject) => {
                const data = [];
                BX24.callMethod(method, params, result => {
                    if (result.status !== 200 || result.error()) {
                        return reject(result);
                    }

                    if (key) {
                        data.push(...result.data()[key]);
                    } else {
                        data.push(...result.data());
                    }

                    if (typeof result.more !== 'function' || !result.more()) {
                        return resolve(data);
                    }

                    if (typeof result.next === 'function') {
                        result.next();
                    }
                });
            });
        },

        async fetchApplications () {
            const params = {
                entityTypeId: 190,
                select: ['ufCrm_1_1650622987', 'ufCrm_1_1650623047', 'ufCrm_1_1634029060877', 'createdTime']
            };

            const filter = {
                '!=ufCrm_1_1650622987': ''
            };
            if (this.filter.subdivision) {
                filter.ufCrm_1_1634029060877 = this.filter.subdivision;
            }
            if (Array.isArray(this.filter.datePicked) && this.filter.datePicked.length === 2) {
                filter['>createdTime'] = dayjs(this.filter.datePicked[0]).format();
                filter['<createdTime'] = dayjs(this.filter.datePicked[1])
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59)
                    .format();
            }
            params.filter = filter;
            this.application = await this.callListMethod('crm.item.list', params, 'items');
        },
        async fetchSubdivisions () {
            const data = await this.callMethod('crm.item.fields', { entityTypeId: 190 });
            this.subdivisions = data.fields?.ufCrm1_1634029060877?.items;
        },
        async fetchDissmisalType () {
            this.dismissalReasons = await this.callMethod('lists.element.get', {
                IBLOCK_TYPE_ID: 'lists',
                IBLOCK_ID: '39'
            });
        }
    }
};
</script>

<style lang="scss">

.content {
    display: flex;
    margin: 0 auto;
}

.side {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0;
    max-width: 250px;

    &__title {
        font-family: Arial, sans-serif;
        font-size: 20px;
        margin-bottom: 15px;
    }

    &__select {
        width: 100%;
        margin-bottom: 75px;
    }

    &__date-select {
        margin-bottom: 75px;
        width: 100%;
    }
}

.chart {
    width: 100%;
    padding: 0 0 0 40px;
    height: 600px;
}

body {
    font-family: Arial, sans-serif;
    overflow: hidden;
    margin: 0;
}
</style>
