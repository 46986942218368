<template>
    <div>
        <el-date-picker
            v-model="currentValue"
            type="daterange"
            :picker-options="pickerOptions"
            format="dd/MM/yyyy"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd"
            clearable
        />
    </div>
</template>

<script>
export default {
    name: 'SelectDate',
    props: {
        value: {
            type: Array,
            required: false,
            default: null
        }
    },
    data () {
        return {
            pickerOptions: {
                firstDayOfWeek: 1
            }
        };
    },
    computed: {
        currentValue: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: unset;
}
</style>
