import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/src/locale/lang/ru-RU';
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);

Vue.component('ApexCharts', VueApexCharts);

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

new Vue({
    render: h => h(App)
}).$mount('#app');
