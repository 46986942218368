<template>
    <apex-charts
        type="pie"
        :options="chartOptions"
        :series="data"
        height="100%"
    />
</template>

<script>

export default {
    name: 'ApexChart',
    props: {
        data: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
        };
    },
    computed: {
        chartOptions () {
            return {
                labels: this.labels,
                chart: {
                    type: 'pie',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    colors: '#000',
                    sparkline: {
                        enabled: false
                    }
                },
                responsive: [{
                    breakpoint: 1175,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                noData: {
                    text: 'Нет данных',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: 'black',
                        fontSize: '30px',
                        fontFamily: 'Arial'
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                tooltip: {
                    enabled: true
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    },
                    active: {
                        filter: {
                            type: 'none' /* none, lighten, darken */
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        color: '#000'
                    },
                    background: {
                        enabled: true,
                        foreColor: '#000',
                        color: 'black',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                legend: {
                    show: true,
                    position: 'right',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: 14,
                    horizontalAlign: 'left',
                    onItemClick: {
                        toggleDataSeries: false
                    },
                    onItemHover: {
                        highlightDataSeries: false
                    }
                }
            };
        }
    }

};
</script>

<style lang="scss">
.apexcharts-legend{
    max-width: 400px;
}
@media (max-width: 1175px){
    .apexcharts-legend{
        max-width: 100%;
    }
}
</style>
